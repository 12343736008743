import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const user = JSON.parse(localStorage.getItem('userData'));
const token = user?.accessToken;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common = { Authorization: `bearer ${token}` };

export const getContestData = createAsyncThunk('contest/getData', async params => {
    const response = await axios.get(`/contest/list`, { params: params });
    // console.log('response :>> ', response);
    const data = response?.data?.contestData;
    return {
        params,
        data: data?.data,
        totalPages: data?.totalPages,
        perPage: data?.perPage,
        currentPage: data?.currentPage,
        totalItems: data?.totalItems
    };
});

export const getContest = createAsyncThunk('contest/getContest', async id => {
    const contestId = id;

    const res = await axios.post('/contest/edit', { id: contestId });
    return res.data;
});

// export const getPriceDetails = createAsyncThunk('contest/getPriceAmountList', async contestid => {
//     const contestId = contestid;
//     const res = await axios.post('/contest/price/list', {
//         contestId: contestId
//     });
//     console.log('res price list:>> ', res);
//     return res.data;
// });

export const appUsersSlice = createSlice({
    name: 'contest',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getContestData.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.params = action.payload.params;
                state.total = action.payload.totalPages;
                state.currentPage = action.payload.currentPage;
                state.totalItems = action.payload.totalItems;
            }).addCase(getContest.fulfilled, (state, action) => {
                state.selectedUser = action.payload;
            });
        // .addCase(getPriceDetails.fulfilled, (state, action) => {
        //     state.priceDetails = action.payload;
        // });
    }
});

export default appUsersSlice.reducer;
