export const filterData = async (response, params) => {
  const { q = "", sort = "desc", sortColumn = "id" } = params;
  /* eslint-disable  */
  const queryLowered = q?.toLowerCase();
  const dataAsc = response?.data?.sort((a, b) =>
    a[sortColumn] < b[sortColumn] ? -1 : 1
  );
  const dataToFilter = sort === "asc" ? dataAsc : dataAsc?.reverse();
  return [
    {
      total: response?.totalItems,
      list: dataToFilter,
    },
  ];
};
