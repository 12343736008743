import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const user = JSON.parse(localStorage.getItem('userData'));
const token = user?.accessToken;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common = { Authorization: `bearer ${token}` };

export const getFoodMealsData = createAsyncThunk('food/getData', async params => {
  const response = await axios.get(`/foodmeals`, { params: params });
  const data = response.data.mealsData;
  return {
    params,
    data: data?.data,
    totalPages: data?.totalPages,
    perPage: data?.perPage,
    currentPage: data?.currentPage,
    totalItems: data?.totalItems
  };
});

export const appUsersSlice = createSlice({
  name: 'food',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFoodMealsData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalItems = action.payload.totalItems;
      });
  }
});

export default appUsersSlice.reducer;
