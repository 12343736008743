import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const user = JSON.parse(localStorage.getItem('userData'));
const token = user?.accessToken;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common = { Authorization: `bearer ${token}` };
import { filterData } from '@src/common/dataTableFilter';

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data');
  return response.data;
});

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`/users/list`, { params: params });
  // const datas = await filterData(response.data.userdata, params);
  return {
    params,
    data: response?.data?.userdata,
    totalPages: response?.data?.userdata?.totalPagess
  };
});

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`/user/${id}`);
  return {
    data: response.data.userData,
    following: response.data.followingCount,
    follower: response.data.followerCount,
  };
});

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user);
  await dispatch(getData(getState().users.params));
  await dispatch(getAllData());
  return user;
});

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id });
  await dispatch(getData(getState().users.params));
  await dispatch(getAllData());
  return id;
});

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    follower: 0,
    following: 0,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  }
});

export default appUsersSlice.reducer;
